import React from 'react'
import { graphql } from 'gatsby'
import PageBuilder from '../components/pageBuilder'
import Helmet from 'react-helmet'
import SEO from '../components/seo'

export const query = graphql`
  query KontaktPageQuery {
    kontaktPage: sanityPage(slug: { current: { eq: "kontakt" } }) {
      id
      title
      ...PageBuilder
    }
  }
`

const KontaktPage = props => {
  const { data, errors } = props
  const pageData = data && data.kontaktPage
  return (
    <>
      {pageData && pageData.title && <SEO title={pageData.title} />}
      <Helmet
        bodyAttributes={{
          class: 'is-page-kontakt',
        }}
      />
      {pageData && (
        <PageBuilder
          pageBuilder={pageData.pageBuilder}
          _rawPageBuilder={pageData._rawPageBuilder}
        />
      )}
    </>
  )
}

export default KontaktPage
